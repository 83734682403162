//uniqlo-id
.style10 {
    position: relative;
    overflow: hidden;

    //banner
    .ant-carousel {
        min-height: 820px;

        .slick-list {
            .slick-track {
                display: flex;
            }

            .img_wrap {
                height: auto;

                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    min-height: 820px;
                    object-position: center top;
                }
            }
        }
    }
    .banner {
        position: relative;

        .sub-title {
            display: block;
            width: 400px;
            position: absolute;
            z-index: 30;
            top: 1rem;
            right: 5%;
            img.desktop{
                display: block;
                margin: 0 auto;
                width: 18rem;
            }
            img.mobile{
                display:none;
            }
        }
    }

    //header
    .header {
        background-color: #111;

        &-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            overflow: hidden;
            padding: 22px 16px;
        }
        .logo-wrapper {
            flex: none;
            width: 220px;
        }

        &-form {
            display: grid;
            grid-auto-flow: column;
            gap: 0.5rem;
            .box {
                display: flex;
                flex-direction: column;

                input {
                    text-overflow: ellipsis;
                    padding: 0.25rem;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    border: 1px solid #ccc;
                    outline: 2px solid transparent;
                    outline-offset: 2px;
                }
                &>*+* {
                    margin-top: 0.25rem;
                }
            }

            &-wrapper {
                &>*+* {
                    margin-top: 0.5rem;
                }
            }

            .web-input {
                margin-top: 0.125rem;

                &:focus {
                    border: 1px solid @betway-green;
                }
            }

            .error-code {
                // display: none;
                font-size: 13px;
                color: @betway-error;
            }
        }

        .login {
            display: inline-block;
            border-radius: 0.25rem;
            font-size: 0.875rem;
            line-height: 1.25rem;
            padding: 0.375rem 0.75rem;
            background-color: @betway-green;
            color: #fff;

            &.mobile {
                display: none;
            }
        }

        &-action {
            display: flex;
            justify-content: flex-end;

            &>*+* {
                margin-left: 0.75rem;
            }

            .text-link {
                cursor: pointer;
                font-size: 0.875rem;
                line-height: 1.25rem;
                color: @betway-green;

                &:hover {
                    opacity: 0.8;
                }

                svg {
                    display: inline-block;
                    height: 1.25rem;
                    width: 1.25rem;
                    vertical-align: middle;
                    margin-right: 0.25rem;
                }
            }
        }
    }

    // form
    .central {
        position: absolute;
        right: 5%;
        left: auto;
        top: 17rem;
        z-index: 40;
        width: 400px;

        .title {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            text-align: center;
            padding: 1rem 0;
            background-color: @betway-green-dark;

            h3 {
                color: #fff;
                font-size: 22px;
                font-weight: 900;
            }
        }
        .desc {
            text-align: center;
            padding: 1rem 0.625rem;
            background-color: @betway-green;
            &>*+* {
                margin-left: 0.5rem;
            }

            span {
                display: inline-block;
                text-align: left;
                min-width: 265px;
                max-width: 70%;
                line-height: 22px;
                vertical-align: middle;
                color: #FFF;
                &.full {
                    max-width: 92%;
                }
            }
            .icon {
                display: inline-block;
                width: 72px;
                vertical-align: middle;
            }
        }
        .form-wrapper {
            padding: 1.5rem 1.75rem;
            color: #fff;
            background-color: #111;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            
            .form-block {
                &>*+* {
                    margin-top: 1rem;
                }

                .login-item {
                    display: grid;
                    row-gap: 0.5rem;
                    -moz-column-gap: 0.25rem;
                    column-gap: 0.25rem;

                    &.grid-cols-4 {
                        grid-template-columns: repeat(4, minmax(0, 1fr));
                    }

                    .form-label {
                        display: grid;
                        align-content: center;
                        font-size: 1rem;
                        // line-height: 1.5rem;
                        line-height: 18px;
                    }

                    .input-control {
                        color: #FFF;
                        outline: 2px solid transparent;
                        outline-offset: 2px;
                        margin: 0 0.125rem;
                        border-width: 1px;
                        border-color: rgb(38, 38, 38, 1);
                        background-color: rgb(38, 38, 38, 1);
                        font-size: 0.875rem;
                        line-height: 1.25rem;

                        &.disabled {
                            cursor: not-allowed;
                            text-align: center;
                        }

                        &.birthday {
                            padding-right: 1.75rem;
                        }

                        &:focus {
                            border: 1px solid @betway-green;
                        }
                    }

                    .error-txt {
                        margin-left: 0.5rem;
                        margin-right: 0.5rem;
                        word-break: break-all;
                        font-size: 0.75rem;
                        line-height: 1rem;
                        color: @betway-error;
                    }

                    .col-span-2 {
                        grid-column: span 2 / span 2;
                    }

                    .col-span-3 {
                        grid-column: span 3 / span 3;
                    }

                    .col-start-2 {
                        grid-column-start: 2;
                    }

                    .col-start-3 {
                        grid-column-start: 3;
                    }
                }

                .login-hint {
                    .text-link {
                        cursor: pointer;
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                        color: @betway-green;
        
                        &:hover {
                            opacity: 0.8;
                        }
        
                        svg {
                            display: inline-block;
                            height: 1.25rem;
                            width: 1.25rem;
                            vertical-align: middle;
                            margin-right: 0.25rem;
                        }
                    }
                }
            }
        }

        .submit {
            margin: 0 auto;
            display: block;
            cursor: pointer;
            white-space: nowrap;
            border-radius: 0.25rem;
            border-style: none;
            background-color: @betway-green;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            text-align: center;
            font-size: 1.25rem;
            line-height: 1.75rem;
            font-weight: 900;
            color: #fff;
            &.login {
                width: 100%;
                font-size: 1rem;

            }
        }
    }

    //qr-code
    .QR-Block {
        padding-top: 0 !important;
        text-align: center;

        &>*+* {
            margin-top: 1rem;
        }

        .qrcode {
            display:block;
            
            .device-tab {
                display: flex;
                padding-top: 0.5rem;
                padding-bottom: 1.5rem;

                .tab {
                    display: inline-flex;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    border-bottom-width: 2px;
                    border-color: transparent;
                    padding: 0px 1rem;
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 2.5rem;
                    color: rgb(153 153 153);
                    cursor: pointer;
                    background-repeat: no-repeat;

                    &.active {
                        color: @betway-green;
                        border-color: @betway-green;

                    }

                    .icon {
                        height: 1.25rem;
                        width: 1.25rem;
                        vertical-align: middle;
                    }
                }
            }

            &>*+* {
                margin-top: 1rem;
            }

            .qrcodeImg {
                margin: 0 auto;
            }
        }

        .downloadBlock {
            .mobileImg {
                margin: 0 auto;
                width: 60%;
                padding-bottom: 1.5rem;
            }
        }
        .btn-area {
            .btn-download {
                display: block;
                position: fixed;
                bottom: 0px;
                left: 0px;
                right: 0px;
                background-color: @betway-green;
                padding: 0.75rem 0;
                font-size: 22px;
                font-weight: 600;
                color: #FFF;
                text-decoration: none;
                text-align: center;
                cursor: pointer;
            }
        }

        .tutorial {
            color: #999;
            line-height: 17px;
            font-size: 0.75rem;
            text-align: left;

            &-title {
                color: #FFF;
            }

            &>*+* {
                margin-top: 0.5rem;
            }

            &-list {
                padding-left: 1.25rem;
                list-style-type: decimal;
                
                &>*+* {
                    margin-top: 0.25rem;
                }
            }
        }
    }
}

//color setting
.style10.purple {
    //header
    .header {
        &-form {
            .web-input {
                &:focus {
                    border: 1px solid @betway-purple;
                }
            }
        }

        .login {
            background-color: @betway-purple;
            border: 1px solid #fff;
        }

        &-action {
            .text-link {
                color: @betway-purple;
            }
        }
    }

    // form
    .central {
        .title {
            background-color: @betway-purple-dark;
        }
        .desc {
            background-color: @betway-purple;
        }
        .form-wrapper {
            .form-block {
                .login-item {
                    .input-control {
                        &:focus {
                            border: 1px solid @betway-purple;
                        }
                    }
                }
                .login-hint {
                    .text-link {
                        color: @betway-purple;
                    }
                }
            }
        }

        .submit {
            background-color: @betway-purple;
        }
    }
}

@import url('./mobile.less');